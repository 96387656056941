import React, { useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";

const Marcas = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return(
        <section>
            <div className="marcas">
                <img data-aos="slide-up" src="../../img/bodytite.png" alt="BODYTITE"/>
                <img data-aos="slide-down" src="../../img/microaire.png" alt="MICROAIRE"/>
                <img data-aos="slide-up" src="../../img/vaser.png" alt="VASER"/>
                <img data-aos="slide-down" src="../../img/morpheus.png" alt="MORPHEUS"/>
            </div>
        </section>
    )
}

export default Marcas