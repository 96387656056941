import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCPnASu3cO9wduIM3I56AuyQkfi290fjP8",
    authDomain: "web-federicoluque.firebaseapp.com",
    projectId: "web-federicoluque",
    storageBucket: "web-federicoluque.appspot.com",
    messagingSenderId: "178732699680",
    appId: "1:178732699680:web:4a9dff7488561e603b6c45",
    measurementId: "G-8S3J8519F5"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = getFirestore(app)

