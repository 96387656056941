import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const AcidoHialuronico = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="acido-hialuronico">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'left' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-acidohialuronico.webp")` }}>
                <h2 style={{ 
                    color: "#333333", 
                    marginLeft: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'left' : 'center'}` }}>
                    ÁCIDO HIALURÓNICO
                </h2>
            </div>
            <div className="container">
                {/* <video controls >
                    <source src="../../video/luque.mp4" type="video/mp4"/>
                </video> */}
                <div className="img">
                    <img src={"../../img/clinique/novideo-acidohialuronico.webp"} />
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>ÁCIDO HIALURÓNICO</h3>
                            <article>
                                El tratamiento con Ácido Hialurónico rellenará algunas áreas para hacerlas más voluminosas visualmente.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ES EL ÁCIDO HIALURÓNICO?</h4>
                            <article>
                                El ácido hialurónico es una molécula natural que produce el cuerpo humano. Con la edad, las reservas de ácido hialurónico disminuyen. Por ejemplo, a los 50 años solo queda el 50% del ácido hialurónico inicial y como consecuencia aparecen las arrugas y la piel se vuelve flácida.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <article>
                                Generalmente, debido al paso de los años nuestro organismo deja de producirlo naturalmente o lo producen en menor cantidad. En consecuencia, la piel pierde elasticidad, vitalidad y firmeza.
                                <br/><br/>
                                El tratamiento con ácido hialurónico rellenará algunas áreas  para hacerlas más voluminosas visualmente. Su acción perdurará durante unos meses y proporcionará un efecto de
                                juventud.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>
                                EL ÁCIDO HIALURÓNICO ES BIOCOMPATIBLE, POR LO QUE NO GENERA ALERGIAS NI RECHAZOS.
                            </h4>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>RESULTADOS</h4>
                            <article>
                                - Elimina las arrugas.
                                <br/><br/>
                                - Aporta volumen.
                                <br/><br/>
                                - Brinda a la piel de firmeza, elasticidad e hidratación.
                            </article>
                        </SwiperSlide>

                </Swiper>   
                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-acidohialuronico.webp"} />
                </div>
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Ácido Hialurónico. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default AcidoHialuronico