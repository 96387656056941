import { useState, useEffect} from 'react'

export const useFirestore = (asyncFn, dependencies = []) => {
    const [testimonios, setTestimonios] = useState([])
    const [galeria, setGaleria] = useState([])
    const [procedimientos, setProcedimientos] = useState([])
    const [tratamientos, setTratamientos] = useState([])
    const [historiaClinica, setHistoriaClinica] = useState([])
    const [reserva, setReserva] = useState([])
    
    // HISTORIA CLINICA
    useEffect(() => {

        asyncFn().then(response => {
            setHistoriaClinica(response)
        })
    }, dependencies)

    // RESERVA
    useEffect(() => {

        asyncFn().then(response => {
            setReserva(response)
        })
    }, dependencies)

    // GALERIA
    useEffect(() => {

        asyncFn().then(response => {
            setGaleria(response)
        })
    }, dependencies)

    // TRATAMIENTOS
    useEffect(() => {

        asyncFn().then(response => {
            setTratamientos(response)
        })
    }, dependencies)

    // TESTIMONIOS

    useEffect(() => {

        asyncFn().then(response => {
            setTestimonios(response)
        })
    }, dependencies)

    // PROCEDIMIENTOS

    useEffect(() => {

        asyncFn().then(response => {
            setProcedimientos(response)
        })
    }, dependencies)

    return {
        galeria,
        testimonios,
        procedimientos,
        tratamientos,
        historiaClinica,
        reserva
    }
}