import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Profhilo = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="profhilo">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'right' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-profhilo.webp")` }}>
                <h2 style={{ 
                    color: "#ffffff", 
                    marginRight: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'right' : 'center'}` }}>
                    PROFHILO
                </h2>
            </div>
            <div className="container">

                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-profhilo.webp"} />
                </div>
                
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>PROFHILO</h3>
                            <article>
                                Lo último en rejuvenecimiento facial.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>LO ÚLTIMO EN REJUVENECIMIENTO FACIAL, COMPUESTO DE ÁCIDO HIALURÓNICO CON UN ALTO GRADO DE PUREZA.</h3>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ES EL PROFHILO?</h4>
                            <article>
                                Lo último en rejuvenecimiento facial, compuesto de ácido hialurónico que produce un efecto bioestimulante y regenerador de todo el contorno facial con un alto grado de pureza, siendo un excelente revitalizador nutriendo las células de la dermis y recuperando la firmeza de la piel. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿PARA QUÉ SIRVE EL PROFHILO?</h4>
                            <article>
                                - Flacidez de cara, cuello y escote.
                                <br/><br/>
                                - Pequeñas arrugas de expresión, alisando la piel.
                                <br/><br/>
                                - Duración más prolongada.
                                <br/><br/>
                                - Volumen a tejidos, redibujando el contorno facial.
                                <br/><br/>
                                - Mejora las pieles apagadas, o pieles fotoenvejecidas.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>BENEFICIOS</h4>
                            <article>
                            - No se requiere anestesia y la aplicación es prácticamente indolora.
                            <br/><br/>
                            - Solo requiere 5 puntos de inyección.
                            <br/><br/>
                            - El tratamiento se realiza en solo 15 minutos.
                            <br/><br/>
                            - Se puede volver a sus actividades normales de forma inmediata.
                            <br/><br/>
                            - Resultados visibles desde el segundo día (en la mayoría de los casos).
                            <br/><br/>
                            - Tratamiento alternativo para los pacientes no aptos o que no quieran hilos tensores.
                            <br/><br/>
                            - Produce una doble acción: redensificar y tensar.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CÓMO SE UTILIZA PROFHILO COMO REJUVENECEDOR FACIAL?</h4>
                            <article>
                                Las características únicas de Profhilo® permiten que el producto sea aplicado a través de una sencilla técnica patentada denominada TÉCNICA BAP (Bio Aesthetic Point).
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>LA TÉCNICA BAP</h4>
                            <article>
                                Técnica creada para el tercio medio e inferior del rostro que tiene una mayor predisposición a la atrofia dérmica causada por los efectos del envejecimiento.
                                <br/><br/>
                                Los puntos BAP corresponden a zonas anatómicamente seguras donde hay ausencia de grandes vasos y ramas nerviosas, minimizando así los riesgos y maximizando la difusión del producto en las zonas de aplicación.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>LA TÉCNICA BAP</h4>
                            <article>
                                La ténica BAP en rosto y cuello consiste en:
                                <br/><br/>
                                - Técnica para el tercio medio inferior del rostro, que involucra cinco puntos de inyección por lado.
                                <br/><br/>
                                - En cuello se aplica la misma técnica patentada que abarca diez puntos.
                                <br/><br/>
                                - Esta técnica también puede aplicarse en el cuerpo, abarcando diez puntos por zona con la BAP tool de Profhilo Body, tales como brazos, piernas, glúteos y abdomen.
                            </article>
                        </SwiperSlide>

                </Swiper> 

                {/* <video controls >
                    <source src="../../video/luque.mp4" type="video/mp4"/>
                </video> */}

                <div className="img">
                    <img src={"../../img/clinique/novideo-profhilo.webp"} />
                </div>
                
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Profhilo. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default Profhilo