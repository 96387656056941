import React, { useLayoutEffect } from "react";
import BannerHome from "./Banners/BannerHome";
import Intro from "./Intro/Intro";
import ProcedimientosHome from "./Procedimientos/ProcedimientosHome";
import FedericoLuque from "./FedericoLuque/FedericoLuque";
import Marcas from "../Marcas/Marcas"
import BannerHomeContacto from "./Banners/BannerHomeContacto";
import BannerHomeClinique from "./Banners/BannerHomeClinique";
import VerGaleria from "./VerGaleria/VerGaleria";
import ContactoForm from "../ContactoForm/ContactoForm";

import "aos/dist/aos.css";

const Home = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return(
        <div>
            <BannerHome />
            <Intro />
            <ProcedimientosHome />
            <FedericoLuque />
            {/* <BannerHomeClinique /> */}
            <Marcas />
            <BannerHomeContacto />
            <VerGaleria />
            <ContactoForm />
        </div>
    )
}

export default Home