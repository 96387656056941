import HistoriaClinicaDetalle from "../HistoriaClinicaDetalle/HistoriaClinicaDetalle";

import { useParams } from 'react-router-dom';
import { getHistoriaClinica } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';


const HistoriaCliniDetalleContainer = () => {
    
    const { historiaClinicaId } = useParams()
    const { historiaClinica } = useFirestore(() => getHistoriaClinica(historiaClinicaId))
        
    return (
        <section>
            <HistoriaClinicaDetalle {...historiaClinica}/>
        </section>
    )
}

export default HistoriaCliniDetalleContainer