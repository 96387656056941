import React, { useLayoutEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { getProcedimientos } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore'

import Banner from "../Banners/Banner";
import Marcas from "../Marcas/Marcas"
import ContactoForm from "../ContactoForm/ContactoForm";

const ProcedimientosDetalle = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const { procedimientoId } = useParams()
    const { procedimientos } = useFirestore(() => getProcedimientos())
    
    const prode = procedimientos.filter( procedimiento => 
        procedimiento.id === procedimientoId )

    return(
        <section>
            <Banner />
            {prode.map(pro => {
                return(
                    <div className="procedimiento" key={pro.id}>
                        <h4>{(pro.categoria).toUpperCase()}</h4>
                        <h2>{pro.nombre}</h2>
                        <div className="container-procedimiento">
                            <div className="img">
                                { pro.img == undefined
                                    ? <img src={`../../img/galeria/dr-federico-luque.webp`} alt={pro.nombre} />
                                    : <img src={`../../img/procedimientos/${pro.img}`} alt={pro.nombre} />
                                }
                            </div>
                            <div className="detalle-procedimiento">
                                <article>
                                    <span className="titulo">PROCEDIMIENTO</span><br/>
                                    { pro.procedimiento == undefined
                                    ? `${pro.nombre}`
                                    : `${pro.procedimiento}`
                                    }
                                </article>
                                {/* <article>
                                    <span className="titulo">DURACIÓN</span><br/>
                                    {pro.duracion}
                                </article>
                                <article>
                                    <span className="titulo">ANESTESIA</span><br/>
                                    {pro.anestesia}
                                </article>
                                <article>
                                    <span className="titulo">HOSPITALIZACIÓN</span><br/>
                                    {pro.hospitalizacion}
                                </article>
                                <article>
                                    <span className="titulo">POSIBLES EFECTOS SECUNDARIOS</span><br/>
                                    {pro.efectosSecundarios}
                                </article>
                                <article>
                                    <span className="titulo">RIESGOS</span><br/>
                                    {pro.riesgos}
                                </article>
                                <article>
                                    <span className="titulo">RECUPERACIÓN</span><br/>
                                    {pro.recuperacion}
                                </article> */}
                            </div>
                        </div>
                        <Link to="/procedimientos" className="btnN">VOLVER</Link>
                    </div>
                )})
            }
            <Marcas />
            <ContactoForm />
        </section>
    )
}

export default ProcedimientosDetalle