import React, { useEffect } from 'react'
import Aos from "aos";

import { Link } from "react-router-dom"

import "aos/dist/aos.css";

const FedericoLuque = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return (
        <section>
            <div className='federico-luque'>
                <div data-aos="fade-right" className='img'>
                    <img src='./img/home-dr-luque.webp' alt='Dr Federico Luque' />
                </div>
                <div className='info'>
                    <div>
                        <h4>BODY SCULPTOR</h4>
                        <h2>Dr. Federico Luque</h2>
                    </div>
                    <article>
                        “Mi motivación, mi pasión, es descubrir la 
                        belleza que cada uno lleva dentro como un 
                        escultor que talla con su cincel, el cirujano 
                        utiliza sus cánulas, su bisturí para lograr el mismo objetivo, llegar a sentir y visualizar la perfección de cada cuerpo de cada rostro en esa escultura.”
                    </article>
                    <Link to='/dr-federico-luque' className='btnN'>CONOCER MÁS</Link>
                </div>
            </div>
        </section>
    )
}

export default FedericoLuque