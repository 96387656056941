import React from "react";

const Banner = () => {

    const url = window.location.pathname.split("/")
    
    return(
        <div className="banner-section" style ={ {backgroundImage: `url('../../img/${url[2] ? url[2] : url[1]}-banner.webp')` } }>
        </div>
    )
}

export default Banner