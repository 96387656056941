import React from "react";

import './styles.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import FooterClinique from './components/FooterClinique/FooterClinique';
import NavBar from './components/NavBar/NavBar';
import NavBarClinique from './components/NavBarClinique/NavBarClinique';
import Home from './components/Home/Home';
import DrLuque from "./components/DrLuque/DrLuque";
import Procedimientos from "./components/Procedimientos/Procedimientos";
import ProcedimientosDetalle from "./components/ProcedimientosDetalle/ProcedimientosDetalle";
import Quirofanos from "./components/Quirofanos/Quirofanos";
import Testimonios from "./components/Testimonios/Testimonios";
import Galeria from "./components/Galeria/Galeria";
import Contacto from "./components/Contacto/Contacto";
import Clinique from "./components/Clinique/Clinique";
import HistoriaClinica from "./components/HistoriaClinica/HistoriaClinica";
import HistoriaCliniDetalleContainer from "./components/HistoriaClinicaDetalleContainer/HistoriaClinicaDetalleContainer";
import Reserva from "./components/Reserva/Reserva";
import ReservaDetalleContainer from "./components/Reserva/ReservaDetalleContainer";

const App = () => {

  const currentUrl = window.location.pathname.split("/")

  return (
    <div className='appContainer'>
      <BrowserRouter>
          <header>
            {
              currentUrl[1] == 'clinique'
              ? <NavBarClinique />
              : <NavBar />
            }
          </header>
          <main>
            <Routes>
              <Route path='/' element={ <Home /> }/>
              <Route path='/dr-federico-luque' element={ <DrLuque /> }/>
              <Route path='/procedimientos' element={ <Procedimientos /> }/>
              <Route path='/procedimientos/:categoriaId/:procedimientoId' element={ <ProcedimientosDetalle /> }/>
              <Route path='/quirofanos' element={ <Quirofanos /> }/>
              <Route path="/testimonios" element={ <Testimonios /> }/>
              <Route path="/galeria" element={ <Galeria /> }/>
              <Route path='/galeria/:categoriaId' element={ <Galeria /> } />
              <Route path="/contacto" element={ <Contacto /> }/>
              <Route path="/clinique" element={ <Clinique /> }/>
              <Route path="/historia-clinica" element={ <HistoriaClinica /> }/>
              <Route path="/historia-clinica/:historiaClinicaId" element={ <HistoriaCliniDetalleContainer /> }/>
              <Route path="/sena" element={ <Reserva/> } />
              <Route path="/sena/:reservaId" element={ <ReservaDetalleContainer /> } />
            </Routes>
          </main>
          {
              currentUrl[1] == 'clinique'
              ? <FooterClinique />
              : <Footer />
            }
      </BrowserRouter>
    </div>
  );
}

export default App;