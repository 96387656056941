import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";

import "aos/dist/aos.css";

const Procedimientos = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    const refresh = () => window.location.pathname.reload(true)

    return(
        <section>
            <div className="procedimientos-home">
                <div data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" } className="container" style ={ {backgroundImage: "url('./img/home-cuerpo.webp')" } }>
                    <Link to='/procedimientos' className="btnB">CORPORAL</Link>
                </div>
                <div data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } className="container" style ={ {backgroundImage: "url('./img/home-mamas.webp')" } }>
                    <Link to='/procedimientos' className="btnB">MAMAS</Link>
                </div>
                <div data-aos={ window.innerWidth >= 1000 ? "fade-up" : "fade-right" } className="container" style ={ {backgroundImage: "url('./img/home-no-quirurgicos.webp')" } }>
                    <Link to='/clinique/bodytite' className="btnB" onClick={refresh}>NO QUIRÚRGICAS</Link>
                </div>
                <div data-aos={ window.innerWidth >= 1000 ? "fade-down" : "fade-left" } className="container" style ={ {backgroundImage: "url('./img/home-hombres.webp')" } }>
                    <Link to='/procedimientos' className="btnB">HOMBRES</Link>
                </div>
            </div>
        </section>
    )
}

export default Procedimientos