import React, { useRef } from "react";
import Banner from "../Banners/Banner";
import { useReactToPrint } from "react-to-print";

const HistoriaClinicaDetalle = (
    {
        nombre,
        apellido,
        dni,
        fechadenacimiento,
        edad,
        nacionalidad,
        paisresidencia,
        direccion,
        piso,
        departamento,
        localidad,
        codigopostal,
        provincia,
        ocupacion,
        coberturamedica,
        plan,
        nroafiliado,
        email,
        telefono,
        motivoconsulta,
        altura,
        peso,
        antecedentesquirurgicos,
        antecedentescuales,
        cirugiasesteticas,
        cirugiascuales,
        anocirugia,
        medicocirugia,
        conformecirugia,
        antecedentespatologicos,
        patologiascuales,
        enfermedadactual,
        tomamedicacion,
        medicacioncual,
        tomaanticonceptivos,
        antecedentetrombofiliacoagulacion,
        alergia,
        alergiacual,
        vacunacovid,
        vacunacovidcuantas,
        vacunacovidcuales,
        covidinternacion,
        covidinternaciondias,
        covidsecuela,
        covidquesecuela,
        embarazos,
        embarazoscuantos,
        cesareaopartonatural,
        abortos,
        hijosmas,
        varices,
        alimentacionequilibrada,
        alimentaciontipo,
        actividadfisica,
        actividadfisicatipo,
        actividadfisicafrecuencia,
        fuma,
        desdecuandofuma,
        quefuma,
        fumacuantos,
        fumo,
        fumocuantos,
        cuandodejofumar,
        alcohol,
        alcoholcuando,
        alcoholcuanto,
        cambiohabitopostintervencion,
        comoconocioluque,
    }
) => {

    const componentPDF = useRef();

    const generatePDF = useReactToPrint({
        content: ()=> componentPDF.current,
        documentTitle: `HC-${nombre}-${apellido}-${dni}`,
        onafterprint:()=>alert("Historia clínica descargada")
    })

    return(
        <section className="historia-clinica-detalle">
            <Banner />
            <div ref={componentPDF}>
                <div className="container-historia-clinica-detalle">
                    <h2>HISTORIA CLÍNICA</h2>
                    <h3>DATOS DEL PACIENTE</h3>
                    <table>
                        <tr>
                            <td className="w100">
                                <span>NOMBRE</span>
                                <br/>
                                {nombre} {apellido}
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>FECHA DE NACIMIENTO</span>
                                <br/>
                                { fechadenacimiento ? fechadenacimiento : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>EDAD</span>
                                <br/>
                                { edad ? edad : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>DNI / ID</span>
                                <br/>
                                { dni ? dni : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>NACIONALIDAD</span>
                                <br/>
                                { nacionalidad ? nacionalidad : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>PAÍS DE RESIDENCIA</span>
                                <br/>
                                { paisresidencia ? paisresidencia : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>DIRECCIÓN</span>
                                <br/>
                                { direccion ? direccion : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>PISO</span>
                                <br/>
                                { piso ? piso : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>DEPARTAMENTO</span>
                                <br/>
                                { departamento ? departamento : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>LOCALIDAD</span>
                                <br/>
                                { localidad ? localidad : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>CÓDIGO POSTAL</span>
                                <br/>
                                { codigopostal ? codigopostal : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>PROVINCIA</span>
                                <br/>
                                { provincia ? provincia : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w100">
                                <span>OCUPACIÓN</span>
                                <br/>
                                { ocupacion ? ocupacion : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>COBERTURA MÉDICA</span>
                                <br/>
                                { coberturamedica ? coberturamedica : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>COBERTURA Y PLAN</span>
                                <br/>
                                { plan ? plan : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>NÚMERO AFILIADO</span>
                                <br/>
                                { nroafiliado ? nroafiliado : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>EMAIL</span>
                                <br/>
                                { email ? email : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>TELÉFONO</span>
                                <br/>
                                { telefono ? telefono : <div> - </div> }
                            </td>
                        </tr>
                    </table>
                </div>

                <div className="container-historia-clinica-detalle">
                    <h3>HISTORIA CLÍNICA DEL PACIENTE</h3>
                    <table>
                        <tr>
                            <td className="w100">
                                <span>MOTIVO DE LA CONSULTA</span>
                                <br/>
                                { motivoconsulta ? motivoconsulta : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>ALTURA</span>
                                <br/>
                                { altura ? altura : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>PESO</span>
                                <br/>
                                { peso ? peso : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w25">
                                <span>ANTECEDENTES QUIRÚRGICOS</span>
                                <br/>
                                { antecedentesquirurgicos ? antecedentesquirurgicos : <div> - </div> }
                            </td>
                            <td className="w75">
                                <span>¿QUÉ ANTECEDENTES?</span>
                                <br/>
                                { antecedentescuales ? antecedentescuales : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w25">
                                <span>CIRUGÍAS ESTÉTICAS</span>
                                <br/>
                                { cirugiasesteticas ? cirugiasesteticas : <div> - </div> }
                            </td>
                            <td className="w75">
                                <span>¿QUÉ CIRUGÍAS?</span>
                                <br/>
                                { cirugiascuales ? cirugiascuales : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>AÑO DE LA CIRUGÍA</span>
                                <br/>
                                { anocirugia ? anocirugia : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>MÉDICO CIRUJANO</span>
                                <br/>
                                { medicocirugia ? medicocirugia : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿QUEDÓ CONFORME?</span>
                                <br/>
                                { conformecirugia ? conformecirugia : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w25">
                                <span>ANTECEDENTES PATOLÓGICOS</span>
                                <br/>
                                { antecedentespatologicos ? antecedentespatologicos : <div> - </div> }
                            </td>
                            <td className="w75">
                                <span>¿CUÁLES?</span>
                                <br/>
                                { patologiascuales ? patologiascuales : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>ENFERMEDADES PREEXISTENTES</span>
                                <br/>
                                { enfermedadactual ? enfermedadactual : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿TOMA MEDICAMENTOS?</span>
                                <br/>
                                { tomamedicacion ? tomamedicacion : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿QUÉ MEDICAMENTOS?</span>
                                <br/>
                                { medicacioncual ? medicacioncual : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>¿TOMA ANTICONCEPTIVOS?</span>
                                <br/>
                                { tomaanticonceptivos ? tomaanticonceptivos : <div> - </div> }
                            </td>

                            <td className="w50">
                                <span>TROMBOFILIA / ALTERACIONES EN LA COAGULACIÓN</span>
                                <br/>
                                { antecedentetrombofiliacoagulacion ? antecedentetrombofiliacoagulacion : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>ALERGIAS</span>
                                <br/>
                                { alergia ? alergia : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>¿QUÉ ALERGIAS?</span>
                                <br/>
                                { alergiacual ? alergiacual : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>VACUNA COVID-19</span>
                                <br/>
                                { vacunacovid ? vacunacovid : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿CUÁNTAS VACUNAS?</span>
                                <br/>
                                { vacunacovidcuantas ? vacunacovidcuantas : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿QUÉ VACUNAS?</span>
                                <br/>
                                { vacunacovidcuales ? vacunacovidcuales : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>INTERNACIÓN POR COVID-19</span>
                                <br/>
                                { covidinternacion ? covidinternacion : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>¿CUÁNTOS DÍAS?</span>
                                <br/>
                                { covidinternaciondias ? covidinternaciondias : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>¿ALGUNA SECUELA POR COVID-19?</span>
                                <br/>
                                { covidsecuela ? covidsecuela : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>¿QUÉ SECUELA/S?</span>
                                <br/>
                                { covidquesecuela ? covidquesecuela : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w50">
                                <span>EMBARAZOS</span>
                                <br/>
                                { embarazos ? embarazos : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>¿CUÁNTOS EMBARAZOS?</span>
                                <br/>
                                { embarazoscuantos ? embarazoscuantos : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>¿CESÁREA O NATURAL?</span>
                                <br/>
                                { cesareaopartonatural ? cesareaopartonatural : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿ABORTOS?</span>
                                <br/>
                                { abortos ? abortos : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿TIENE PENSADO TENER MÁS HIJOS?</span>
                                <br/>
                                { hijosmas ? hijosmas : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w100">
                                <span>¿VÁRICES?</span>
                                <br/>
                                { varices ? varices : <div> - </div> }
                            </td>
                        </tr>
                    </table>
                </div>

                <div className="container-historia-clinica-detalle">
                    <h3>HÁBITOS</h3>
                    <table>
                        <tr>
                        <td className="w50">
                                <span>¿ALIMENTACIÓN EQUILIBRADA?</span>
                                <br/>
                                { alimentacionequilibrada ? alimentacionequilibrada : <div> - </div> }
                            </td>
                            <td className="w50">
                                <span>¿DE QUÉ TIPO?</span>
                                <br/>
                                { alimentaciontipo ? alimentaciontipo : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                        <td className="w33">
                                <span>¿ACTIVIDAD FÍSICA?</span>
                                <br/>
                                { actividadfisica ? actividadfisica : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿DE QUÉ TIPO?</span>
                                <br/>
                                { actividadfisicatipo ? actividadfisicatipo : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>FRECUENCIA</span>
                                <br/>
                                { actividadfisicafrecuencia ? actividadfisicafrecuencia : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w25">
                                <span>¿FUMA?</span>
                                <br/>
                                { fuma ? fuma : <div> - </div> }
                            </td>
                            <td className="w25">
                                <span>¿DESDE CUANDO?</span>
                                <br/>
                                { desdecuandofuma ? desdecuandofuma : <div> - </div> }
                            </td>
                            <td className="w25">
                                <span>¿QUÉ FUMA?</span>
                                <br/>
                                { quefuma ? quefuma.join(', ') : <div> - </div> }
                            </td>
                            <td className="w25">
                                <span>¿CUÁNTOS POR DÍA?</span>
                                <br/>
                                { fumacuantos ? fumacuantos : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>¿FUMÓ?</span>
                                <br/>
                                { fumo ? fumo : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿CUÁNTOS POR DÍA?</span>
                                <br/>
                                { fumocuantos ? fumocuantos : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿CUÁNDO DEJÓ DE FUMAR?</span>
                                <br/>
                                { cuandodejofumar ? cuandodejofumar : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w33">
                                <span>¿CONSUME ALCOHOL?</span>
                                <br/>
                                { alcohol ? alcohol : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿CUANDO?</span>
                                <br/>
                                { alcoholcuando ? alcoholcuando : <div> - </div> }
                            </td>
                            <td className="w33">
                                <span>¿CON QUÉ FRECUENCIA?</span>
                                <br/>
                                { alcoholcuanto ? alcoholcuanto : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className="w100">
                                <span>¿CAMBIARÍA HÁBITOS POST INTERVENCIÓN SEGÚN RECOMENDACIÓN MÉDICA?</span>
                                <br/>
                                { cambiohabitopostintervencion ? cambiohabitopostintervencion : <div> - </div> }
                            </td>
                        </tr>
                    </table>
                </div>

                <div className="container-historia-clinica-detalle">
                    <h3>¿CÓMO CONOCIÓ AL DR FEDERICO LUQUE?</h3>
                    <table>
                        <tr>
                            <td className="w100">
                                <br/>
                                { comoconocioluque ? comoconocioluque : <div> - </div> }
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
            <button className="btnN" style={{margin:"50px"}} onClick={ generatePDF }>
                DESCARGAR HISTORIA CLÍNICA
            </button>

        </section>
    )
}

export default HistoriaClinicaDetalle