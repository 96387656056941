import React, { useLayoutEffect } from "react";

import Marcas from "../Marcas/Marcas";
import Tratamientos from "./Tratamientos/Tratamientos";
import ContactoFormClinique from "./ContactoClinique/ContactoFormClinique";
import BannerDrLuque from "./BannerDrLuque/BannerDrLuque";
import GoUp from "../Assets/GoUp";

const Clinique = () => {

    const url = window.location.pathname.split("/")

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
        
    return(
        <section>
            <div className="banner-clinique" style ={ {backgroundImage: `url('../../img/${url[1] ? url[1] : url[2]}-banner.webp')` } }>
            </div>
            <div className='clinique'>
                <div className="clinique-logo">
                    <img src='../../img/clinique/clinique-logoN.webp' alt='Clinique' />
                </div>
                <div className="container-intro">
                    <h2>La mejor y última tecnología a nivel mundial para el tensado cutáneo</h2>
                    {/* <video width="280" height="500" controls >
                        <source src="../../video/luque.mp4" type="video/mp4"/>
                    </video> */}
                </div>
            </div>
            <Tratamientos />
            <Marcas />
            <ContactoFormClinique />
            <BannerDrLuque />
            <GoUp/>
        </section>
    )
}

export default Clinique