import React from "react";

const BannerHome = () => {

    return(
        <section>
            <div className="banner-home" style ={ {backgroundImage: `url('../img/banner-home.webp')` } }>
                <h1>
                    “LA COMBINACIÓN PERFECTA ENTRE 
                    <br/>
                    UN ESCULTOR Y UN CIRUJANO PLÁSTICO”
                </h1>
            </div>
        </section>
    )
}

export default BannerHome