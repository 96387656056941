import React from "react";

import { LinkHomeLuque, LinkWhatsApp, LinkInstagramClinique } from '../Assets/Variables';

const FooterClinique = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="logo">
                    <img src='../../img/clinique/clinique-logoN.webp' alt='Clinique' />
                </div>
                <div>
                    <a href="#bodytite" className="button">Bodytite</a>
                    <a href="#facetite" className="button">Facetite</a>
                    <a href="#morpheus8" className="button">Morpheus8</a>
                    <a href="#evolve" className="button">Evolve</a>
                    <a href="#toxina-botulinica" className="button">Toxina Botulínica</a>
                    <a href="#acido-hialuronico" className="button">Ácido Hialurónico</a>
                    <a href="#profhilo" className="button">Profhilo</a>
                    <a href="#sculptra" className="button">Sculptra</a>
                    <a href="#contacto-clinique" className="button">Contacto</a>
                    {LinkHomeLuque}
                </div>
                <div>
                    {LinkInstagramClinique}
                    {LinkWhatsApp}
                </div>
            </div>
            <div className="miembro">
                <img src="../../img/saps.png" alt="saps"/>
                <img src="../../img/scpba.png" alt="scpba"/>
                <img src="../../img/total-definer.png" alt="total definer"/>
            </div>
        </footer>
    )
}

export default FooterClinique