import React from "react";

const Intro = () => {

    return(
        <section>
            <div className="intro">
                <h4>BODY SCULPTOR</h4>
                <h2>CIRUJANO PLÁSTICO Y RECONSTRUCTIVO</h2>
                <h3>Dr. Federico Luque</h3>
                <article>
                Con 12 años de experiencia profesional como cirujano siempre ha sobresalido por su intensa capacitación desde la presentación de gran cantidad de trabajos científicos en congresos de cirugía plástica ganando premios en los mismos como su intensa participación en congresos y cursos internacionales principalmente Estados Unidos, 
                Colombia, Brasil y Chile adquiriendo de los mismos gran conocimiento para poder brindar a sus pacientes resultados de máxima excelencia.
                </article>
            </div>
        </section>
    )
}

export default Intro