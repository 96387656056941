import React from "react";

import { Link } from 'react-router-dom';

const LogoB = () => {
    return (
        <Link to="/">
                <img src="../../img/logoB.webp" alt="Dr. Federico Luque" className="logo"/>
        </Link>
    )
}

export default LogoB