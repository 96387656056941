import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Evolve = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="evolve">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'left' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-evolve.webp")` }}>
                <h2 style={{ 
                    color: "#fff", 
                    marginLeft: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'left' : 'center'}` }}>
                    EVOLVE
                </h2>
            </div>
            <div className="container">
                <div className="img">
                    <img src={"../../img/clinique/novideo-evolve.webp"} />
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>EVOLVE</h3>
                            <h4>
                                REMODELACIÓN DE LA PIEL, TRATAMIENTO DE GRASAS Y TONIFICACIÓN MUSCULAR.
                            </h4>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>EVOLVE TITE</h3>
                            <article>
                                Un procedimiento no invasivo diseñado específicamente para remodelar múltiples áreas del cuerpo para mejorar la apariencia de su piel.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ES EVOLVE TITE?</h4>
                            <article>
                                Evolve Tite ofrece una innovadora solución estética para remodelar su piel de manos libres. Es un tratamiento rápido y fácil que se puede realizar durante la hora del almuerzo y se realiza en la comodidad de una sala de tratamiento.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>
                                ¿CÓMO FUNCIONA EVOLVE TITE?
                            </h4>
                            <article>
                                Evolve Tite aprovecha la tecnología de radiofrecuencia bipolar (RF) clínicamente probada para remodelar la piel y mejorar su apariencia.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ PUEDO ESPERAR DURANTE ESTE TRATAMIENTO?</h4>
                            <article>
                                Durante el procedimiento, puede esperar un calentamiento de su piel similar a un masaje con piedras calientes. A medida que la radiofrecuencia penetra en las capas dérmicas, los pacientes suelen notar una reducción en los pliegues de la piel.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>EVOLVE TONE</h3>
                            <article>
                                Un tratamiento no invasivo para mejorar la definición de su físico natural sin el tiempo de inactividad o las cicatrices asociadas con la cirugía invasiva. Tonifique fácilmente su abdomen, caderas y glúteos.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ES EVOLVE TONE?</h4>
                            <article>
                                Evolve Tone es una solución innovadora no invasiva diseñada específicamente para aumentar la fuerza muscular. Es un procedimiento no quirúrgico que mejorará la forma y el tono de su cuerpo.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ PUEDO ESPERAR DURANTE ESTE TRATAMIENTO?</h4>
                            <article>
                                Durante el procedimiento, puede esperar que los aplicadores emitan estimulación muscular eléctrica directa que inducirá contracciones musculares. Sentirá que sus músculos se contraen de manera similar a lo que experimentaría durante un entrenamiento.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>EVOLVE TRANSFORM</h3>
                            <article>
                                Una solución no invasiva para transformar rápidamente la forma de los músculos mientras trata la grasa y mejora el aspecto de su piel. Es el tratamiento combinado perfecto para todas las zonas.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ES EVOLVE TRANSFORM?</h4>
                            <article>
                                Es un procedimiento innovador que desarrolla y fortalece los músculos mientras mejora la apariencia de su piel y trata la grasa sin necesidad de agujas ni cirugía. La combinación de tecnologías ofrece una alternativa sin tiempo de inactividad para esculpir su cuerpo.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CÓMO FUNCIONA EVOLVE TRANSFORM?</h4>
                            <article>
                                Evolve Transform proporciona dos procedimientos en una visita. Energía de radiofrecuencia (RF) para el calentamiento profundo de tejido y grasa, y estimulación muscular eléctrica (EMS) para inducir contracciones musculares forzadas que no se pueden lograr durante los entrenamientos. La combinación le permite mejorar su tono y forma de manera rápida e indolora.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ PUEDO ESPERAR DURANTE ESTE TRATAMIENTO?</h4>
                            <article>
                            Durante su procedimiento, puede esperar un calentamiento de su piel y la contracción de los músculos en el área específica que está siendo tratada.
                            </article>
                        </SwiperSlide>

                </Swiper>   
                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-evolve.webp"} />
                </div>
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Evolve. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default Evolve