import ReservaDetalle from '../Reserva/ReservaDetalle';

import { useParams } from 'react-router-dom';
import { getReserva } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';


const ReservaDetalleContainer = () => {
    
    const { reservaId } = useParams()
    const { reserva } = useFirestore(() => getReserva(reservaId))
        
    return (
        <section>
            <ReservaDetalle {...reserva}/>
        </section>
    )
}

export default ReservaDetalleContainer