import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";

import "aos/dist/aos.css";

const VerGaleria = () => {

    useEffect(() => {
        Aos.init({ duration: 1000, easing: "ease-out", once: true})
    }, [])

    return(
        <section>
            <div className="ver-galeria">
                <div data-aos="fade-right" className="container-img">
                    <img src="./img/home-galeria.webp" />
                </div>
                <div data-aos-duration="2000" data-aos="fade-left" className="container-ver-galeria">
                    <div className="info-ver-galeria">
                        <h4>ANTES Y DESPUÉS</h4>
                        <h2>
                            VER GALERÍA DE 
                            <br/>
                            FOTOS
                        </h2>
                        <article>
                            Conocé el antes y después de algunas de las transformaciones de nuestros pacientes.
                            <br/><br/>
                            Cada cuerpo tuvo un resultado diferente, cada cuerpo es único.
                        </article>
                        <Link to='/galeria' className="btnN">VER +</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VerGaleria