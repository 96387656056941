import React from "react";
import { Link } from "react-router-dom";

const BannerHomeContacto = () => {

    return(
        <section>
            <div className="banner-home-contacto" style ={ {backgroundImage: `url('../img/home-banner-contacto.webp')` } }>
                <Link to='/contacto' className="btnB">HACÉ TU CONSULTA</Link>
            </div>
        </section>
    )
}

export default BannerHomeContacto