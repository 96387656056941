import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Bodytite = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="bodytite">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'right' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-bodytite.webp")` }}>
                <h2 style={{ 
                    color: "#fff", 
                    marginRight: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'right' : 'center'}` }}>
                    BODYTITE
                </h2>
            </div>
            <div className="container">
                <video controls >
                    <source src="../../video/bodytite.mp4" type="video/mp4"/>
                </video>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>BODYTITE</h3>
                            <article>
                            Renovación de todo el cuerpo: Contorno corporal mínimamente invasivo.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>RENOVACIÓN DE TODO EL CUERPO:</h3>
                            <br/>
                            <h4>CONTORNO CORPORAL</h4>
                            <br/>
                            <h4>MÍNIMAMENTE INVASIVO </h4>
                            <article>
                                Todos nos esforzamos por sentirnos y vernos mejor. La dieta y el ejercicio son las piedras angulares para mantenerse en forma, pero a veces no son suficientes. Ahora tenés la respuesta: BodyTite.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ES BODYTITE?</h4>
                            <article>
                                BodyTite es una gran solución para las personas que buscan reducir la grasa sin la consecuente piel flácida y arrugada, y para la celulitis flácida en muslos y glúteos. También ayuda a mejorar la flacidez de la piel después de la pérdida de peso o debido al envejecimiento. 
                                <br/><br/>
                                BodyTite es un procedimiento mínimamente invasivo que da forma, tensa y levanta sin necesidad del bisturí o la cicatriz. 
                                <br/><br/>
                                Muchísimas famosos ya lo han probado siendo el tratamiento de preferencia para lograr tensado cutáneo en todas las áreas corporales. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>
                            LA VANGUARDIA EN EL REMODELADO CORPORAL
                            </h4>
                            <article>
                                BodyTite utiliza tecnología RFAL (Lipoplastia Asistida por Radio Frecuencia) que aborda algunas de las deficiencias asociadas con la liposucción tradicional o los levantamientos corporales quirúrgicos. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ AREAS PUEDEN SER TRATADAS? </h4>
                            <article>
                                Una solución integral para todo tu cuepo: el abdomen, los brazos, las rodillas y los muslos internos/externos son algunas de las zonas de tratamiento más comunes. 
                                <br/><br/>
                                En una sola visita se pueden tratar múltiples áreas. Cada zona tomará aproximadamente 15 minutos de tratamiento. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUE TAN RÁPIDO VERÉ LOS RESULTADOS? </h4>
                            <article>
                                Los resultados se pueden ver inmediatamente, con mejores resultados observables después de 6 meses y de forma continua hasta 12 meses. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CUÁL ES EL TIEMPO DE REPOSO? </h4>
                            <article>
                                El tiempo de reposo depende del área de tratamiento, este puede oscilar entre 1-2 días y un máximo de 10 días. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>EFECTOS BODYTITE: </h4>
                            <article>
                                BodyTite trata suavemente la grasa en las áreas difíciles mientras tensa la piel, lo que ayuda a esculpir tu cuerpo en forma definitiva. 
                            </article>
                            <h4>ÁREAS DE TRATAMIENTO:</h4>
                            <article>
                                BRAZOS
                                <br/>
                                ABDOMEN
                                <br/>
                                GLÚTEOS 
                                <br/>
                                PIERNAS
                            </article>
                        </SwiperSlide>

                </Swiper>   
                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-bodytite.webp"} />
                </div>
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Bodytite. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default Bodytite