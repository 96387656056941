import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Morpheus8 = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="morpheus8">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'left' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-morpheus8.webp")` }}>
                <h2 style={{ 
                    color: "#fff", 
                    marginLeft: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'left' : 'center'}` }}>
                    MORPHEUS8
                </h2>
            </div>
            <div className="container">
                
                <video controls >
                    <source src="../../video/morpheus.mp4" type="video/mp4"/>
                </video>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>MORPHEUS8</h3>
                            <article>
                                Estimula la producción de Colágeno y rejuvenece tu piel para descubrir un aspecto más joven.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>¿QUÉ ES MORPHEUS8? </h3>
                            <article>
                                Morpheus8 es un tratamiento fraccional para la piel que estimula la producción de colágeno de las capas subyacentes de la dermis. 
                                <br/><br/>
                                Al dirigirse a las capas más profundas de la piel, los tejidos de la cara y el cuerpo pueden ser remodelados para revelar una apariencia juvenil más radiante. 
                                <br/><br/>
                                No solo tensa la piel sino que mejora su calidad rejuveneciendo la misma dando un aspecto fresco y natural.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ÁREAS PUEDEN SER TRATADAS? </h4>
                            <article>
                                Morpheus8 puede utilizarse en cualquier zona que pueda beneficiarse de la renovación de la piel y la renovación subdermal. 
                                <br/><br/>
                                Las zonas más comúnmente tratadas incluyen la cara, el área periorbital, el abdomen, los muslos, las piernas y los glúteos. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CUÁNTAS SESIONES SE RECOMIENDAN? </h4>
                            <article>
                                La cantidad de sesiones se recomendarán en función de sus objetivos. Los tiempos y la frecuencia del tratamiento se adaptarán específicamente para obtener los resultados óptimos. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ RÁPIDO VERÉ LOS RESULTADOS? </h4>
                            <article>
                                Los resultados visibles se pueden ver en unos pocos días, pero generalmente después de tres semanas son más notables los resultados. 
                                <br/><br/>
                                Las mejoras continúan hasta tres meses después del tratamiento. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CUÁL ES EL BENEFICIO DE LA ENERGÍA FRACCIONADA? </h4>
                            <article>
                                La energía de radiofrecuencia (RF) es un método científicamente probado para remodelar y reconstruir el colágeno. Es mínimamente invasivo, usando una matriz de micro pines para renovar las capas más profundas de la piel sin casi ningún tiempo de inactividad del paciente. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CUALQUIER PERSONA PUEDE USAR MORPHEUS8? </h4>
                            <article>
                                El beneficio de la tecnología daltónica de Morpheus8 es que se puede usar en todo tipo de piel. Las propiedades únicas permiten tratar tonos más oscuros.                            
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿SE PUEDE USAR EN COMBINACIÓN CON OTROS TRATAMIENTOS? </h4>
                            <article>
                                Sí, Morpheus8 se puede usar en la misma sesión de tratamiento, o unos días después de otros tratamientos. Morpheus8 también se puede combinar con otros tratamientos energéticos o inyectables de InMode para lograr resultados más extensos. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CUÁNDO ME PUEDO PONER MAQUILLAJE DESPUÉS DEL TRATAMIENTO? </h4>
                            <article>
                                Dado que el tiempo de inactividad del paciente es mínimo, el maquillaje se puede aplicar de 1 a 2 días después del tratamiento. Los pacientes deben esperar ver enrojecimiento que cede en el transcurso de las horas. 
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CUÁLES SON LAS RECOMENDACIONES DESPUÉS DEL PROCEDIMIENTO?  </h4>
                            <article>
                                Los pacientes deben hidratar el área de tratamiento y evitar la exposición directa al sol. También se recomienda que los pacientes usen un buen protector solar de amplio espectro todos los días. 
                                <br/><br/>
                                Además de reducir su riesgo de cáncer de piel, los protectores solares también retrasan el proceso de envejecimiento.
                            </article>
                        </SwiperSlide>

                </Swiper>   

                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-morpheus8.webp"} />
                </div>
                
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Morpheus8. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default Morpheus8