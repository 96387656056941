import React, { useState, useRef } from 'react';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';
import SignatureCanvas from 'react-signature-canvas';

import swal from 'sweetalert';
import Banner from '../Banners/Banner';

const Reserva = () => {
    
    const form = useRef();
    const [reserva, setReserva] = useState({
        date: new Date().toLocaleDateString('es-AR'),
        nombre: '',
        dni: '',
        reserva: '',
        tratamiento: '',
        email: '',
        firma: '',
    });

    const signRef = useRef();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const handleClear = () => {
        signRef.current.clear();
        setValue('firma', ''); // Limpiar el valor del campo firma en el formulario
        setReserva({ ...reserva, firma: '' });
    };

    const handleGenerate = () => {
        if (signRef.current.isEmpty()) {
        swal('¡Ups!', 'La firma es obligatoria', 'error');
        } else {
        const dataURL = signRef.current.getTrimmedCanvas().toDataURL('image/png');
        setValue('firma', dataURL); // Asignar la imagen de la firma al campo firma en el formulario
        setReserva({ ...reserva, firma: dataURL });
        }
    };

    const sendEmail = () => {
        emailjs
        .sendForm('landing', 'mail-reserva', form.current, '0MTk0AxmZ5JHk97LX')
        .then((result) => {
            console.log(result.text);
        })
        .catch((error) => {
            console.log(error.text);
        });
    };

    const createReserva = () => {
        if (!reserva.firma) {
        swal('¡Ups!', 'La firma es obligatoria', 'error');
        return;
        }

        setDoc(doc(db, 'reserva', reserva.dni), reserva)
        .then(() => {
            swal('GRACIAS POR TU CONTACTO', `Recibimos el documento de tu seña`, 'success');
            sendEmail();
            navigate('/');
        })
        .catch((error) => {
            console.log(error);
            swal('¡Ups!', `Algo falló`, 'error');
        });
    };

    const currentDate = new Date().toLocaleDateString('es-AR');

    return (
        <section className="reserva">
            <Banner />
            <div className="container-reserva">
                <h2>SEÑA</h2>
                <form ref={form} onSubmit={handleSubmit(createReserva)}>
                    <article>
                        Buenos Aires, {currentDate} 
                    </article>
                    <article>
                    Por medio del presente, y en este acto, la/ el paciente 
                    <input 
                            className="input-form"
                            type="text" 
                            name="nombre" 
                            placeholder="Nombre"
                            value={reserva.nombre}
                            {...register("nombre", { required: "El campo NOMBRE es obligatorio" })}
                            onChange={(e) => setReserva({...reserva, nombre: e.target.value})}
                        />
                    <ErrorMessage
                        errors={errors}
                        name="nombre"
                        render={({ message }) => <p>{message}</p>}
                    />
                    DNI N°
                    <input 
                        className="input-form"
                        type="text" 
                        name="dni" 
                        value={reserva.dni}
                        placeholder="DNI"
                        {...register("dni", { required: "eL campo DNI es obligatorio" })}
                        onChange={(e) => setReserva({...reserva, dni: e.target.value})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="dni"
                        render={({ message }) => <p>{message}</p>}
                    />
                    le entrega al Dr. Federico Luque, y éste acepta, la suma de $ (EN LETRA Y EN NUMERO) 
                    <input 
                        className="input-form"
                        type="text" 
                        name="reserva" 
                        value={reserva.reserva}
                        placeholder="Monto $"
                        {...register("reserva", { required: "El campo MONTO $ es obligatorio" })}
                        onChange={(e) => setReserva({...reserva, reserva: e.target.value})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="reserva"
                        render={({ message }) => <p>{message}</p>}
                    />
                    en concepto de seña a cuenta del precio final por el tratamiento programado de común acuerdo consistente en 
                    <input 
                        className="input-form"
                        type="text" 
                        name="tratamiento" 
                        value={reserva.tratamiento}
                        placeholder="Tratamiento"
                        {...register("tratamiento", { required: "El campo TRATAMIENTO es obligatorio" })}
                        onChange={(e) => setReserva({...reserva, tratamiento: e.target.value})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="tratamiento"
                        render={({ message }) => <p>{message}</p>}
                    />
                    sirviendo el presente de suficiente recibo y carta de pago. El saldo pendiente será abonado el día del procedimiento, previo a su realización.
                    </article>
                    
                    <article>
                    Declaro que la firma inserta en el presente formulario digital enviado por el Dr. Luque a través del correo electrónico 
                    <input 
                        className="input-form"
                        type="email" 
                        name="email" 
                        value={reserva.email}
                        placeholder="Email"
                        {...register("email", { required: "El campo EMAIL es obligatorio" })}
                        onChange={(e) => setReserva({...reserva, email: e.target.value})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => <p>{message}</p>}
                    />
                    pertenece a mi puño y letra. Conste
                    </article>

                    <article>
                        En caso de arrepentimiento y/o si la paciente no se presentara el día pautado para llevar a cabo el procedimiento contratado, perderá la suma abonada en concepto de seña, renunciando a reclamos judiciales de cualquier naturaleza.
                    </article>

                    <SignatureCanvas
                        canvasProps={{ className: 'sigCanvas' }}
                        ref={signRef}
                        clearOnResize={false}
                        onEnd={handleGenerate}
                    />

            <div className="container-btn-canvas">
                <button type="button" onClick={handleClear} className="btnN">
                BORRAR FIRMA
                </button>
            </div>

            {errors.firma && <ErrorMessage errors={errors} name="firma" render={({ message }) => <p>{message}</p>} />}

            {/* <article>En prueba de conformidad, firman médico y paciente al pie del presente. Conste.</article> */}
            {reserva.firma ? <input type="submit" value="ENVIAR" className="btnN" /> : null}
            </form>
        </div>
        </section>
    );
};

export default Reserva;