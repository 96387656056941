import React from "react";

import LogoN from "../Assets/LogoN";
import { LinkDrLuque, LinkProcedimientos, LinkClinique, LinkQuirofanos, LinkTestimonios, LinkContacto, LinkGaleria, LinkWhatsApp, LinkInstagram } from '../Assets/Variables';


const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div>
                    < LogoN />
                </div>
                <div>
                    {LinkDrLuque}
                    {LinkProcedimientos}
                    {LinkQuirofanos}
                    {LinkTestimonios}
                    {LinkGaleria}
                    {LinkContacto}
                    {/* {LinkClinique} */}
                </div>
                <div>
                    {LinkInstagram}
                    {LinkWhatsApp}
                </div>
            </div>
            <div className="miembro">
                <img src="../../img/saps.png" alt="saps"/>
                <img src="../../img/scpba.png" alt="scpba"/>
                <img src="../../img/total-definer.png" alt="total definer"/>
            </div>
        </footer>
    )
}

export default Footer