import React, { useLayoutEffect } from "react";

import Banner from "../Banners/Banner"
import Marcas from "../Marcas/Marcas"
import ContactoForm from "../ContactoForm/ContactoForm";

const Contacto = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return(
        <section>
            <Banner />
            <ContactoForm />
            <Marcas />
        </section>
    )
}

export default Contacto