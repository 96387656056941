import React, { useLayoutEffect } from "react";

import Banner from "../Banners/Banner";
import Marcas from "../Marcas/Marcas"
import ContactoForm from "../ContactoForm/ContactoForm";

const Quirofanos = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return(
        <section>
            <Banner />
            <div className="quirofanos">
                <h4>UBICACIONES</h4>
                <h2>QUIRÓFANOS</h2>
                <div className="container-quirofanos">
                    <article>
                    La seguridad sanitaria es fundamental a la hora de realizar cualquier cirugía.
                    <br/><br/>
                    Las intervenciones se realizan en quirófanos que cuentan con una UTI (Unidad de Cuidados Intensivos) y las condiciones de equipamientos y condiciones higiénicas necesarias.
                    </article>
                    {/* <div className="detalle-quirofano">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>
                        <h3>SANATORIO OTAMENDI</h3>
                        <p>
                            AZCUENAGA 870, RECOLETA
                            <br/>
                            CABA
                        </p>
                        <a href="https://goo.gl/maps/m441b37yk2ZY7SRXA" target="_BLANK"><button className="btnN">VER MAPA</button></a>
                    </div>
                    <div className="detalle-quirofano">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>
                        <h3>CLÍNICA ADVENTISTA</h3>
                        <p>
                            ESTOMBA 1720, BELGRANO
                            <br/>
                            CABA
                        </p>
                        <a href="https://g.page/clinicaadventistabelgrano?share" target="_BLANK"><button className="btnN">VER MAPA</button></a>
                    </div>
                    <div className="detalle-quirofano">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>
                        <h3>CLÍNICA ZABALA</h3>
                        <p>
                            AV. CABILDO 1295, BELGRANO
                            <br/>
                            CABA
                        </p>
                        <a href="https://goo.gl/maps/8yHPUUEFAkSozmkj8" target="_BLANK"><button className="btnN">VER MAPA</button></a>
                    </div>
                    <div className="detalle-quirofano">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>
                        <h3>SANATORIO LAS LOMAS</h3>
                        <p>
                            AV. DIEGO CARMAN 555, SAN ISIDRO
                            <br/>
                            BUENOS AIRES
                        </p>
                        <a href="https://goo.gl/maps/x9ygAUTb6UCyFpFY7" target="_BLANK"><button className="btnN">VER MAPA</button></a>
                    </div> */}
                </div>
            </div>
            <Marcas />
            <ContactoForm />
        </section>
    )
}

export default Quirofanos