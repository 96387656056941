import React from "react";
import { Link } from "react-router-dom";
import LogoB from "../../Assets/LogoB";

const BannerDrLuque = () => {

    const refresh = () => window.location.pathname.reload(true)

    return(
        <section>
            <div className="home-banner-dr-luque" style ={ {backgroundImage: `url('../img/home-banner-dr-luque.webp')` } }>
                <img src="../../img/logoB.webp" alt="Dr. Federico Luque" className="logo-luque-banner-clinique"/>
                <Link to='/' className="btnB" onClick={refresh}>DRFEDERICOLUQUE.COM</Link>
            </div>
        </section>
    )
}

export default BannerDrLuque