import React, { useRef } from "react";
import Banner from "../Banners/Banner";
import { useReactToPrint } from "react-to-print";

const ReservaDetalle = (
    {
        date,
        nombre,
        dni,
        reserva,
        tratamiento,
        email,
        firma,
    }
) => {

    const componentPDF = useRef();

    const generatePDF = useReactToPrint({
        content: ()=> componentPDF.current,
        documentTitle: `SEÑA-${nombre}-${dni}`,
        onafterprint:()=>alert("Reserva descargada")
    })

    return(
        <section className="reserva-detalle">
            <Banner />
            <div ref={componentPDF}>
                <div className="container-reserva-detalle">
                <h2>SEÑA</h2>
                <table>
                    <tr>
                        <td className="w100">
                            Buenos Aires, {date} 
                            <br/><br/>
                            Por medio del presente, y en este acto, la/ el paciente <span>{nombre}</span>, DNI N° <span>{dni}</span>,                     
                            le entrega al Dr. Federico Luque, y éste acepta, la suma de $<span>{reserva}</span> en concepto de seña
                            a cuenta del precio final por el tratamiento programado de común acuerdo consistente en <span>{tratamiento}</span> sirviendo el presente de suficiente recibo y carta de pago. 
                            El saldo pendiente será abonado el día del procedimiento, previo a su realización.
                            <br/><br/>
                            En caso de arrepentimiento y/o si la paciente no se presentara el día pautado para llevar a 
                            cabo el procedimiento contratado, perderá la suma abonada en concepto de seña, renunciando a 
                            reclamos judiciales de cualquier naturaleza.
                            <br/><br/>
                            Declaro que la firma inserta en el presente formulario digital enviado por el Dr. Luque a través del
                            correo electrónico <span>{email}</span> pertenece a mi puño y letra. Conste
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <td className="w25">
                            <img src={firma} />
                            <br/>
                            FIRMA PACIENTE
                            <br/><br/>
                            {nombre}
                            <br/>
                            ACLARACIÓN
                            <br/><br/>
                            {dni}
                            <br/>
                            DNI
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <td>
                            <br/><br/>
                            FIRMA MÉDICO
                            <br/><br/><br/>
                            ACLARACIÓN
                            <br/><br/><br/>
                            DNI
                            <br/>
                        </td>
                    </tr>
                </table>                
                </div>
            </div>
            <button className="btnN" style={{margin:"50px"}} onClick={ generatePDF }>
                DESCARGAR RESERVA
            </button>

        </section>
    )
}

export default ReservaDetalle