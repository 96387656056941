import React from "react";

import { Link } from 'react-router-dom';

const LogoN = () => {
    return (
        <Link to="/">
                <img src="../../img/logoN.webp" alt="Dr. Federico Luque" className="logo"/>
        </Link>
    )
}

export default LogoN