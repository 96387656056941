import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom"
import { getProcedimientos } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore'

import Banner from "../Banners/Banner";
import Marcas from "../Marcas/Marcas"
import ContactoForm from "../ContactoForm/ContactoForm";


const Procedimientos = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const refresh = () => window.location.pathname.reload(true)

    const { procedimientos } = useFirestore(() => getProcedimientos())

    const corporal = procedimientos.filter( procedimiento =>
        procedimiento.categoria === "corporal"
    )

    const mamas = procedimientos.filter( procedimiento =>
        procedimiento.categoria === "mamas"
    )

    const noQuirugicas = procedimientos.filter( procedimiento =>
        procedimiento.categoria === "no-quirurgicas"
    )

    const hombres = procedimientos.filter( procedimiento =>
        procedimiento.categoria === "hombres"
    )

    return(
        <section>
            <Banner />
            <div className="procedimientos">
                <h4>DR. FEDERICO LUQUE</h4>
                <h2>PROCEDIMIENTOS</h2>
                <div className="list-procedimientos">
                    <div className="container-procedimientos">
                        <h3>CORPORAL</h3>
                        { corporal.map(prod => {
                            return(
                                <Link to={`/procedimientos/${prod.categoria}/${prod.id}`} className="button" key={prod.id}>
                                    {prod.nombre}
                                </Link>
                            )})
                        }
                    </div>
                    <div className="container-procedimientos">
                        <h3>MAMAS</h3>
                        { mamas.map(prod => {
                            return(
                                <Link to={`/procedimientos/${prod.categoria}/${prod.id}`} className="button" key={prod.id}>
                                    {prod.nombre}
                                </Link>
                            )})
                        }
                    </div>
                    <div className="container-procedimientos">
                        <h3>NO QUIRÚRGICAS</h3>
                        { noQuirugicas.map(prod => {
                            return(
                                <Link to={`/clinique/bodytite`} onClick={refresh} className="button" key={prod.id}>
                                    BODYTITE CLINIQUE
                                </Link>
                            )})
                        }
                    </div>
                    <div className="container-procedimientos">
                        <h3>HOMBRES</h3>
                        { hombres.map(prod => {
                            return(
                                <Link to={`/procedimientos/${prod.categoria}/${prod.id}`} className="button" key={prod.id}>
                                    {prod.nombre}
                                </Link>
                            )})
                        }
                    </div>
                </div>
            </div>
            <Marcas />
            <ContactoForm />
        </section>
    )
}

export default Procedimientos