import React, { useLayoutEffect } from "react";
import { Navigation, Pagination, Autoplay  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NavLink, useParams } from 'react-router-dom';
import { getGaleria } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';

import Banner from "../Banners/Banner";
import Marcas from "../Marcas/Marcas"
import ContactoForm from "../ContactoForm/ContactoForm";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Galeria = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const { categoriaId } = useParams()
    const { galeria } = useFirestore(() => getGaleria(categoriaId), [categoriaId])

    return(
        <section>
            <Banner />
            <div className="galeria">
                <h4>GALERÍA</h4>
                <h2>ANTES Y DESPUÉS</h2>
                <div className="categorias-galeria">
                    <NavLink to='/galeria/' className={({isActive}) => isActive ? "activeLinks" : "links"} >VER TODO</NavLink>
                    <NavLink to='/galeria/corporal' className={({isActive}) => isActive ? "activeLinks" : "links"} >CORPORAL</NavLink>
                    <NavLink to='/galeria/mamas' className={({isActive}) => isActive ? "activeLinks" : "links"} >MAMAS</NavLink>
                    <NavLink to='/galeria/no-quirurgicas' className={({isActive}) => isActive ? "activeLinks" : "links"} >NO QUIRÚRGICAS</NavLink>
                    <NavLink to='/galeria/hombres' className={({isActive}) => isActive ? "activeLinks" : "links"} >HOMBRES</NavLink>
                </div>
                {
                    galeria.length > 0
                    ? <Swiper
                    slidesPerView={1}
                    spaceBetween={200}
                    initialSlide={1}
                    loop={true}
                    pagination={{
                    clickable: true,
                    }}
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                    {galeria.map(prod => {
                        return(
                            <SwiperSlide className="swiper-slide" key={prod.id}>
                                <div className="container-galeria">
                                    <div className="img">
                                        <img src={`../img/galeria/${ prod.imgantes ? `${prod.imgantes}` : "dr-federico-luque.webp"  }`} />
                                    </div>
                                    <div className="info-galeria">
                                        <article>
                                            <span className="titulo">-
                                            <br/>
                                            PROCEDIMIENTO/S: </span> 
                                            {prod.procedimiento}
                                            <br/><br/>
                                            {prod.text}
                                            <br/><br/>
                                            <span className="titulo">POSTOPERATORIO:</span> {prod.postoperatorio}
                                            <br/>
                                            <span className="titulo">-</span>
                                        </article>
                                    </div>
                                    <div className="img">
                                    <img src={`../img/galeria/${ prod.imgdespues ? `${prod.imgdespues}` : "dr-federico-luque.webp"  }`} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )})
                    }
                </Swiper>
                    : <h2>NO HAY SELECCIÓN</h2>
                }
                
            </div>
            <Marcas />
            <ContactoForm />
        </section>
    )
}

export default Galeria