import React, { useLayoutEffect } from "react";

import Banner from "../Banners/Banner";
import Marcas from "../Marcas/Marcas"
import ContactoForm from "../ContactoForm/ContactoForm";

const DrLuque = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return(
        <section>
            <Banner />
            <div className="dr-luque">
                <h4>BODY SCULPTOR</h4>
                <h2>CONOCÉ AL</h2>
                <h3>Dr. Federico Luque</h3>
                <img src="./img/dr-luque-img01.webp" alt="Dr Luque" />
                <article>
                    Con 12 años de experiencia profesional como cirujano siempre ha sobresalido por su intensa capacitación desde la presentación de gran cantidad de trabajos científicos en congresos de cirugía plástica ganando premios en los mismos como su intensa participación en congresos y cursos internacionales principalmente Estados Unidos, Colombia, Brasil y Chile adquiriendo de los mismos gran conocimiento para poder brindar a sus pacientes resultados de máxima excelencia.
                    <br /><br />
                    MÉDICO ESPECIALISTA EN CIRUGÍA PLÁSTICA, RECONSTRUCTIVA Y ESTÉTICA.
                    <br /><br />
                    Sus inicios como Médico de la Universidad de Buenos Aires en el año 2004 graduándose con altas calificaciones fueron el pilar para su posterior formación en el área quirúrgica.
                    <br /><br />
                    Inicia su formación en el área de Cirugía General en la provincia de Córdoba donde sentaron las bases para realizar la Especialización en Cirugía Plástica Estética y Reconstructiva en los mejores Centros de la Ciudad de Buenos Aires, formándose no solo en el área de la Cirugía estética sino logrando una formación completa en el área de quemados, máxilofacial y cirugía pediatrica.
                    <br /><br />
                    Logrando en el final de su Carrera un promedio de excelencia y mención especial con premios en distintos congresos internacionales. Actualmente realiza una gran cantidad de cirugías especializándoce principalmente en el área de cirugía del contorno corporal.
                    <br /><br />
                    “Mi motivación, mi pasión, es descubrir la belleza que cada uno lleva dentro como un escultor que talla con su cincel, el cirujano utiliza sus cánulas, su bisturí para lograr el mismo objetivo, llegar a sentir y visualizar la perfección de cada cuerpo de cada rostro en esa escultura.”
                </article>
                <img src="./img/dr-luque-img02.webp" alt="Dr Luque" />
            </div>
            <Marcas />
            <ContactoForm />
        </section>
    )
}

export default DrLuque