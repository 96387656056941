import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Facetite = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="facetite">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'left' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-facetite.webp")` }}>
                <h2 style={{ 
                    color: "#fff", 
                    marginLeft: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'left' : 'center'}` }}>
                    FACETITE
                </h2>
            </div>
            <div className="container">
                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-facetite.webp"} />
                </div>
                <video controls >
                    <source src="../../video/facetite.mp4" type="video/mp4"/>
                </video>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>FACETITE</h3>
                            <article>
                                El procedimiento más avanzado de contorno y refinamiento facial para revelar un aspecto más joven.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>EL PROCEDIMIENTO MÁS AVANZADO DE CONTORNO Y REFINAMIENTO FACIAL PARA REVELAR UN ASPECTO MÁS JOVEN.</h3>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ES FACETITE?</h4>
                            <article>
                                FaceTite es un nuevo procedimiento que es comparable a un estiramiento facial quirúrgico, pero sin cicatrices.
                                <br/><br/>
                                FaceTite proporciona una mejora sustancial en la flacidez de la piel del rostro y el cuello, que anteriormente solo podía lograrse a través de un procedimiento quirúrgico, como un lifting facial. FaceTite utiliza energía de radiofrecuencia mínimamente invasiva para derretir la grasa y contornear la piel.
                                <br/><br/>
                                Resultados significativos y de larga duración.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ TIPO DE CUIDADOS POSTERIORES AL PROCEDIMIENTO SE REQUIEREN?</h4>
                            <article>
                                FaceTite es un procedimiento mínimamente invasivo. 
                                <br/><br/>
                                Después del procedimiento, los pacientes pueden volver a casa el mismo día, y pueden retomar sus actividades habituales poco después.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ AREAS PUEDEN SER TRATADAS?</h4>
                            <article>
                                Puede ser tratada cualquier área en el rostro y el cuello con la piel flácida. 
                                <br/><br/>
                                Esto incluye el mentón doble, la papada y otras áreas con líneas profundas y piel flácida.                             
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ TAN RAPIDO VERÉ LOS RESULTADOS?</h4>
                            <article>
                                Los resultados se pueden ver inmediatamente, con mejores resultados observables después de 6 meses y de forma continua hasta 12 meses.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿POR QUÉ ESTO ES MEJOR QUE UN LIFTING FACIAL?</h4>
                            <article>
                                Al evitar un procedimiento de escisión para el estiramiento facial, los pacientes no se expondrán a cicatrices, teniendo como ventaja la reducción de la hinchazón y la incomodidad post procedimiento. 
                                <br/><br/>
                                Este es un proceso menos traumático con un resultado final de aspecto natural.    
                                <br/><br/>
                                Uno de los tratamientos elegidos por nuestros pacientes es el tratamiento del cuello laxo o flácido el cual por medio del FaceTite produce un efecto no solo de lipólisis sino también tensado cutáneo obteniendo así un efecto de rejuvenecimiento de características nunca antes vistas.                         
                            </article>
                        </SwiperSlide>

                </Swiper>   
                
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Facetite. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default Facetite