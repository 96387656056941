import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const ToxinaBotulinica = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="toxina-botulinica">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'right' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-toxinabotulinica.webp")` }}>
                <h2 style={{ 
                    color: "#333333", 
                    marginRight: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'right' : 'center'}` }}>
                    TOXINA BOTULÍNICA
                </h2>
            </div>
            <div className="container">
                
                {/* <video controls >
                    <source src="../../video/luque.mp4" type="video/mp4"/>
                </video> */}
                <div className="img">
                    <img src={"../../img/clinique/novideo-toxinabotulinica.webp"} />
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>TOXINA BOTULÍNICA</h3>
                            <article>
                                El tratamiento por excelencia para tratar arrugas de expresión.
                                <br/><br/>
                                Utilizamos productos importados, las mejores marcas de excelencia y calidad.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>EL TRATAMIENTO POR EXCELENCIA PARA TRATAR ARRUGAS DE EXPRESIÓN.</h3>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ ÁREAS PUEDEN SER TRATADAS? </h4>
                            <article>
                                - Frente.
                                <br/><br/>
                                - Entrecejo.
                                <br/><br/>
                                - Patas de gallo.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿CUÁNDO APLICARSE TOXINA BOTULÍNICA?</h4>
                            <article>
                                No hay un rango de edad específico para su aplicación y esta dependerá de las necesidades del paciente. Se recomienda comenzar su uso como una práctica preventiva, colocarlo antes de que aparezca la arruga para nunca tenerla.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                        <h4>RESULTADOS</h4>
                            <article>
                                - Tratamiento seguro.
                                <br/><br/>
                                - Tratamiento ambulatorio.
                                <br/><br/>
                                - Resultados los primeros 5 días.
                            </article>
                            <br/><br/>
                            <h4>BENEFICIOS</h4>
                            <article>
                                - Aspecto más joven.
                                <br/><br/>
                                - Sin necesidad de anestesia.
                                <br/><br/>
                                - Indoloro.
                            </article>
                        </SwiperSlide>

                </Swiper>   

                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-toxinabotulinica.webp"} />
                </div>
                
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Toxina Botulínica. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default ToxinaBotulinica