import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay  } from 'swiper';

import ReactWhatsapp from 'react-whatsapp';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Sculptra = () => {

    const pageWidth = window.innerWidth;

    return(
        <section className="container-tratamientos" id="sculptra">
            <div className="banner-tratamientos" style={{ 
                justifyContent: `${pageWidth>=1000 ? 'right' : 'center'}`, 
                backgroundImage: `url("../../img/clinique/tratamientos-banner-sculptra.webp")` }}>
                <h2 style={{ 
                    color: "#333333", 
                    marginRight: `${pageWidth>=1000 ? '140px' : '0px'}`, 
                    textAlign: `${pageWidth>=1000 ? 'right' : 'center'}` }}>
                    SCULPTRA
                </h2>
            </div>
            <div className="container">
                
                <Swiper
                    slidesPerView={1}
                    spaceBetween={80}
                    initialSlide={0}
                    loop={true}
                    pagination={{ clickable: true, }}
                    autoplay={{ delay: 8000, disableOnInteraction: false, }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                        <SwiperSlide className="swiper-slide" >
                            <h3>SCULPTRA</h3>
                            <article>
                                Sculptra literalmente refresca tu apariencia restaurando los contornos naturales de la piel y alisando las arrugas, arrugas y pliegues.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>¿EN QUÉ CONSISTE Y PARA QUÉ SIRVE LA APLICACIÓN DE SCULPTRA?</h3>
                            <article>
                                Sculptra es un material inyectable biocompatible y biodegradable conocido como ácido poli-L-láctico que estimula naturalmente a sus células para producir su propio colágeno. El resultado da una apariencia más juvenil y de aspecto natural.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>SCULPTRA LITERALMENTE REFRESCA TU APARIENCIA RESTAURANDO LOS CONTORNOS NATURALES DE LA PIEL Y ALISANDO LAS ARRUGAS, ARRUGAS Y PLIEGUES. LOS RELLENOS DÉRMICOS TRADICIONALES SOLO ABORDAN ARRUGAS, LÍNEAS Y PLIEGUES.</h3>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>SCULPTRA FUNCIONA DE MANERA DIFERENTE. REPONE EL VOLUMEN PERDIDO AL ESTIMULAR EL CRECIMIENTO DE SU PROPIO COLÁGENO NATURAL PARA TRATAR LAS CAUSAS SUBYACENTES DEL ENVEJECIMIENTO FACIAL.</h3>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>¿QUÉ RESULTADOS PODREMOS OBTENER?</h4>
                            <article>
                                Los resultados de Sculptra® son muy duraderos hasta dos años o más, en comparación con la duración de los efectos de otros rellenos.
                                <br/><br/>
                                Conseguiremos un aspecto más cuidado de nuestra piel, eliminando de ella la flacidez de una forma muy natural sin necesidad de rellenos.
                                <br/><br/>
                                Sculptra es el único estimulador de colágeno que trata el contorno facial o corporal de manera global. Sculptra redensifica, devuelve el volumen perdido, la calidad, la tensión y elasticidad a la piel.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h3>NUESTROS TRATAMIENTOS TENDRÁN QUE IR BAJO UNA SUPERVISIÓN MÉDICA, POR LO QUE PUEDEN SER TRATADOS PACIENTES QUE NO HAN PERDIDO VOLÚMENES: JAMÁS QUEDAN CARAS «PEPONAS», SIEMPRE CONSIGUIENDO UNOS RESULTADOS NATURALES Y PROGRESIVOS EN EL PACIENTE.</h3>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <h4>USOS</h4>
                            <article>
                                - Rostro.
                                <br/><br/>
                                - Glúteos.
                                <br/><br/>
                                - Abdomen.
                            </article>
                        </SwiperSlide>

                        <SwiperSlide className="swiper-slide" >
                            <article>
                                Una vez finalizado el tratamiento, los resultados son muy duraderos, hasta 2 años, lo que hace de Sculptra uno de los materiales de relleno con mayor duración. El paciente percibe un mejor aspecto de forma gradual desde la primera sesión, siendo a partir del mes cuando se aprecia el resultado definitivo.
                                <br/><br/>
                                ¡Sculptra nos permite en un mismo tratamiento borrar arrugas y dar volumen, a través de la generación de nuevo colágeno!
                            </article>
                        </SwiperSlide>

                </Swiper>   

                <div className="img">
                    <img src={"../../img/clinique/tratamientos-insert-sculptra.webp"} />
                </div>

                {/* <video controls >
                    <source src="../../video/luque.mp4" type="video/mp4"/>
                </video> */}

                <div className="img">
                    <img src={"../../img/clinique/novideo-sculptra.webp"} />
                </div>
                
            </div>
            <br/><br/>
            <ReactWhatsapp number="5491134224028" message={`¡Hola! Te contacto desde Clinique para consultarte por Sculptra. Te pido más info por favor. ¡Gracias!`} target="_BLANK" className="btnN">
                CONSULTAR
            </ReactWhatsapp>
        </section>
    )
}

export default Sculptra