import { db } from ".";
import { getDocs, collection, query, where, getDoc, doc } from 'firebase/firestore'

export const getTestimonios = (testimoniosId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = testimoniosId 
            ? query(collection(db, 'testimonios'), where('id', '==', testimoniosId)) 
            : collection(db, 'testimonios')

            getDocs(collectionRef).then(response => {
                const testimonios = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(testimonios)
            }).catch(error => {
                reject(error)
            })
    })
}

export const getGaleria = (categoriaId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = categoriaId 
            ? query(collection(db, 'galeria'), where('categoria', "array-contains", categoriaId)) 
            : collection(db, 'galeria')

            getDocs(collectionRef).then(response => {
                const galeria = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(galeria)
            }).catch(error => {
                reject(error)
            })
    })
}

export const getHistoriaClinica = (historiaClinicaId) => {
    return new Promise((resolve, reject) => {
            getDoc(doc(db, 'historiaClinica', historiaClinicaId)).then(response => {
                const historiaClinica = { id: response.id, ...response.data()}
                resolve(historiaClinica)
            }).catch(error => {
                reject(error)
            })
    })
}

export const getReserva = (reservaId) => {
    return new Promise((resolve, reject) => {
            getDoc(doc(db, 'reserva', reservaId)).then(response => {
                const reserva = { id: response.id, ...response.data()}
                resolve(reserva)
            }).catch(error => {
                reject(error)
            })
    })
}

export const getProcedimientos = (categoriaId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = categoriaId 
            ? query(collection(db, 'procedimientos'), where('categoria', '==', categoriaId)) 
            : collection(db, 'procedimientos')

            getDocs(collectionRef).then(response => {
                const procedimientos = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(procedimientos)
            }).catch(error => {
                reject(error)
            })
    })
}

