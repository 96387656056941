import React from "react";

import { LinkInstagramClinique } from '../../Assets/Variables';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AcidoHialuronico from "./AcidoHialuronico/AcidoHialuronico";
import Bodytite from "./Bodytite/Bodytite";
import Facetite from "./Facetite/Facetite";
import Morpheus8 from "./Morpheus8/Morpheus8";
import ToxinaBotulinica from "./ToxinaBotulinica/ToxinaBotulinica";
import Profhilo from "./Profhilo/Profhilo";
import Sculptra from "./Sculptra/Sculptra";
import Evolve from "./Evolve/Evolve";


const Tratamientos = () => {

    return(
        <section>
            <div className="tratamientos" id="tratamientos">
                <div className="instagram">
                    {LinkInstagramClinique}
                    <a href='https://www.instagram.com/bodytiteclinique/' target="_BLANK">
                        <h2>@bodytiteclinique</h2>
                    </a> 
                </div>
                <Bodytite/>
                <Facetite/>
                <Morpheus8/>
                <Evolve/>
                <ToxinaBotulinica/>
                <AcidoHialuronico/>
                <Profhilo/>
                <Sculptra/>
            </div>
        </section>
    )
}

export default Tratamientos