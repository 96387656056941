import React, { useLayoutEffect } from "react";

import Banner from "../Banners/Banner"
import Marcas from "../Marcas/Marcas"
import HistoriaClinicaForm from "./HistoriaClinicaForm";

const HistoriaClinica = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return(
        <section>
            <Banner />
            <div style={{margin:"100px 0px 0px 0px", textAlign:"center"}}>
                <h4 style={{fontSize:"14px"}}>COMPLETÁ TU</h4>
                <h2 style={{fontSize:"34px"}}>HISTORIA CLÍNICA</h2>
            </div>
            <HistoriaClinicaForm />
            <Marcas />
        </section>
    )
}

export default HistoriaClinica