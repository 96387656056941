import React, { useState, useRef } from "react";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm  } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';

import swal from 'sweetalert'

const ContactoFormClinique = () => {

    const form = useRef()

    const [clinique, setClinique] = useState({
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        mensaje: ""
    });

    const sendEmail = (e) => {
    
    emailjs.sendForm('contacto', 'clinique', form.current, 'Ep8vE15fL2gJCK7ad')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const createContact = () => {
        
        addDoc(collection(db, "clinique"), {
            clinique
        }).then(() => {
            swal(
                "¡GRACIAS POR CONTACTARTE!", 
                `En breve te estaremos respondiendo`, 
                "success");
            sendEmail()
            navigate('/clinique/bodytite/')
        }).catch(error => {
            console.log(error)
            swal(
                "¡Ups!", 
                `Algo falló`, 
                "error");
        })
    }
    
    return(
        <section>
            <div className="contacto-clinique" id="contacto-clinique">
                <div className="container-form">
                    <h4>¿TENÉS PREGUNTAS?</h4>
                    <h2>CONTACTANOS</h2>
                    <form ref={form} onSubmit={handleSubmit(createContact)}>
                        <input 
                            type="text" 
                            name="nombre" 
                            placeholder="NOMBRE" 
                            value={clinique.nombre}
                            {...register("nombre", { required: "El campo NOMBRE es obligatorio" })}
                            onChange={(e) => setClinique({...clinique, nombre: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="nombre"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="text" 
                            name="apellido" 
                            placeholder="APELLIDO" 
                            value={clinique.apellido}
                            {...register('apellido', { required: "El campo APELLIDO es obligatorio" })}
                            onChange={(e) => setClinique({...clinique, apellido: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="apellido"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="email" 
                            name="email" 
                            placeholder="EMAIL" 
                            value={clinique.email}
                            {...register('email', { required: "El campo EMAIL es obligatorio" })}
                            onChange={(e) => setClinique({...clinique, email: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <input 
                            type="number" 
                            name="telefono" 
                            placeholder="TELÉFONO" 
                            pattern="[0-9]+" 
                            value={clinique.telefono}
                            {...register('telefono', { required: "El campo TELÉFONO es obligatorio" })}
                            onChange={(e) => setClinique({...clinique, telefono: e.target.value})}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="telefono"
                            render={({ message }) => <p>{message}</p>}
                        />

                        <textarea 
                            type="text" 
                            name="mensaje" 
                            rows="6" 
                            placeholder="MENSAJE" 
                            value={clinique.message}
                            {...register('mensaje')}
                            onChange={(e) => setClinique({...clinique, mensaje: e.target.value})}
                        />
                        
                        { !clinique
                            ? ""
                            : <input type="submit" value="ENVIAR" className="btnN" onClick={()=> {}}/>
                        }
                    </form>
                </div>
                <div className="container-img">
                    <img src="../../img/clinique-logoB.webp" alt="Clinique"/>
                </div>
            </div>
        </section>
    )
}

export default ContactoFormClinique