import React, { useLayoutEffect } from "react";
import { Navigation, Pagination, Autoplay  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getTestimonios } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore'

import Banner from "../Banners/Banner";
import Marcas from "../Marcas/Marcas"
import ContactoForm from "../ContactoForm/ContactoForm";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Testimonios = () => {
    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const slidesQuantity = window.innerWidth >= 1000 ? 2 : 1;

    const { testimonios } = useFirestore(() => getTestimonios())
    
    return(
        <section>
            <Banner />
            <div className="testimonios">
                <h4>PACIENTES</h4>
                <h2>TESTIMONIOS</h2>
                <Swiper
                    slidesPerView={slidesQuantity}
                    spaceBetween={80}
                    initialSlide={1}
                    loop={true}
                    pagination={{
                    clickable: true,
                    }}
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper swiper-container"
                    >
                    {testimonios.map(prod => {
                        return(
                            <SwiperSlide className="swiper-slide" key={prod.id}>
                                <h3>{prod.nombre}</h3>
                                <article>
                                    {prod.text}
                                </article>
                            </SwiperSlide>
                        )})
                    }
                </Swiper>
            </div>
            <Marcas />
            <ContactoForm />
        </section>
    )
}

export default Testimonios